<template>
  <div>
    <h4>Export customers to your mailchimp account for email campaigns.</h4>
    <h4>
      <small>Follow these steps to connect to your mailchimp account</small>
    </h4>
    <hr />

    <!-- 				<h4><small>METHOD - 1</small></h4>	 -->
    <h4 class="margin-bottom">Mailchimp Instructions</h4>
    <div>
      <b>Step 1:</b><br />
      <label class="margin-bottom"
        >Click on the Activate button at the right side of the screen.
      </label>
    </div>
    <div>
      <b>Step 2:</b><br />
      <label class="margin-bottom"
        >Wait for the pop-up to appear and login to your Mailchimp account.
      </label>
    </div>
    <div>
      <b>Step 3:</b><br />
      <label class="margin-bottom"
        >Choose the audience list from drop down and click on save button.
      </label>
    </div>
    <div>
      <b>Note:</b><br />
      <label class="margin-bottom"
        >If you change the email address of existing customer, a new customer
        contact will be created in Mailchimp audience list &amp; the old contact
        remains same.
      </label>
    </div>
    <hr />
    <div class="row" id="email-smtp-config">
      <div class="col-md-12">
        <div class="card form-body">
          <div class="card-body">
            <!-- <legend><h5>Payment Method Setting</h5></legend> -->
            <!-- <hr /> -->
            <form>
              <div class="form-group">
                <label for=""
                  >Username <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  name=""
                  class="form-control"
                  id=""
                  v-model="username"
                  :class="errors['username'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['username']" class="text-danger">{{
                  errors["username"][0]
                }}</span>
              </div>

              <div class="form-group mb-1">
                <label for=""
                  >Password <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <input
                    :type="password_type"
                    name=""
                    class="form-control"
                    id=""
                    v-model="password"
                    :class="errors['password'] ? 'border border-danger' : ''"
                  />
                  <div
                    class="input-group-append"
                    style="cursor: pointer"
                    @click="showPassword()"
                  >
                    <span class="input-group-text">
                      <!-- <i class="fa fa-eye"></i> -->
                      <i
                        :class="
                          password_type == 'password'
                            ? 'fa fa-eye'
                            : 'fa fa-eye-slash'
                        "
                      ></i>
                    </span>
                  </div>
                </div>

                <span v-if="errors['password']" class="text-danger">{{
                  errors["password"][0]
                }}</span>
              </div>

              <div class="form-group">
                <label for=""
                  >Merchant Id <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  name=""
                  class="form-control"
                  id=""
                  v-model="merchant_id"
                  :class="errors['merchant_id'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['merchant_id']" class="text-danger">{{
                  errors["merchant_id"][0]
                }}</span>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-success"
              style="float: right"
              ref="kt_save_payment_setting"
              @click="onSubmit()"
            >
              <i class="fa fa-check"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FETCH_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      errors: [],

      id: 0,
      username: "",
      password: "",
      merchant_id: "",

      password_type: "password"
    };
  },

  computed: {
    ...mapGetters(["currentUser"])
  },

  created() {
    this.$store.dispatch(FETCH_PAYMENT_SETTING).then(data => {
      if (data.status == true) {
        this.id = data.data.id;
        this.username = data.data.username;
        this.password = data.data.password;
        this.merchant_id = data.data.merchant_id;
      }
    });
  },
  methods: {
    showPassword() {
      if (this.password_type === "password") {
        this.password_type = "text";
      } else {
        this.password_type = "password";
      }
    },

    onSubmit() {
      this.errors = [];

      const submitButton = this.$refs["kt_save_payment_setting"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var data_ = {
        id_: this.id,
        merchant_id: this.merchant_id,
        username: this.username,
        password: this.password
      };
      // this.loadingButton(ref);
      this.$store
        .dispatch(UPDATE_PAYMENT_SETTING, data_)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          if (this.id == 0) {
            this.id = data.id;
          }
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          var eMessaga = err.error;
          this.$toastr.e(eMessaga);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    }
  }
};
</script>
